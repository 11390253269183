<template>
    <div v-loading.fullscreen.lock="loading">
        <entry-and-detry-search-bar-component
            output_auth="导出出入库记录"
            :fAllClient="fAllClient"
            @search="get_entry_and_detry"
            @output="output_entry_detry"
        ></entry-and-detry-search-bar-component>
        <common-table-component
            :col_arr="fcol_arr"
            :table_data="ftable_data"
        ></common-table-component>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="6">合计应收费用：<span class="collect_moneyc">{{"￥" + this.collect_money | diy_money}}</span></el-col>
                <el-col :span="6">合计应付费用：<span class="pay_moneyc">{{"￥" + this.pay_money | diy_money}}</span></el-col>
                <el-col :span="6">合计入库重量：<span class="entry_weightc">{{this.entry_weight | diy_weight}}</span></el-col>
                <el-col :span="6">合计出库重量：<span class="detry_weightc">{{this.detry_weight | diy_weight}}</span></el-col>
            </el-row>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_entry_and_detry"
        ></common-page-component>
    </div>
</template>

<script>
import { stock_entry_and_detry_request } from '@/network/WareHouse/stock.js'
import { client_list_request} from '@/network/list.js'
import { fomatFloat,thousandBitSeparator} from '@/assets/js/common.js'

import EntryAndDetrySearchBarComponent from '@/components/WareHouse/Stock/EntryAndDetrySearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name: 'EntryAndDetryView',
    data() {
        return {
            loading: true,
            ftotal: 0,
            cond: {
                client_id: '',
                product_name: '',
                bill_name: '',
                order_name: '',
                type: '',
                state: '',
                date: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'state',
                    label: '对账状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未对账'
                            }
                        } else if(d===1) {
                            return {
                                filter_data: '对账中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已对账'
                            }
                        }
                    }
                },
                {
                    prop: 'date',
                    label: '日期',
                    minWidth: '100px',
                },
                {
                    prop: 'type',
                    label: '类型',
                    minWidth: '90px',
                    tag: (d) => {
                        if (d === 'detry') {
                            return {
                                type: 'danger',
                                filter_data: '出库',
                            }
                        }

                        return {
                            type: 'success',
                            filter_data: '入库',
                        }
                    },
                },{
                    prop: 'bill_name',
                    label: '关联单号',
                    minWidth: '120px'
                },{
                    prop: 'client_name',
                    label: '客户',
                    minWidth: '260px',
                },{
                    prop: 'product_name',
                    label: '产品名称',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },{
                    prop: 'weight',
                    label: '重量(KG)',
                    minWidth: '120px',
                    editdata(row) {
                        return thousandBitSeparator(fomatFloat(row['weight']))
                    }
                },{
                    prop: 'tray_num',
                    label: '托盘数(个)',
                    minWidth: '100px'
                },{
                    prop: 'labour_money',
                    label: '应收装卸费(元)',
                    minWidth: '130px',
                    align: 'right',
                    html(row) {
                        return '<span style="color:#00BB00;">' + fomatFloat(row['labour_money']) + '</span>'
                    }
                },{
                    prop: 'pay_labour_money',
                    label: '应付装卸费(元)',
                    minWidth: '130px',
                    align: 'right',
                    html(row) {
                        return '<span style="color:#FF0000;">' + fomatFloat(row['pay_labour_money']) + '</span>'
                    }
                },{
                    prop: 'other_money',
                    label: '应收其他费用(元)',
                    minWidth: '145px',
                    align: 'right',
                    html(row) {
                        return '<span style="color:#00BB00;">' + fomatFloat(row['other_money']) + '</span>'
                    }
                },{
                    prop: 'pay_money',
                    label: '应付其它费用(元)',
                    minWidth: '145px',
                    align: 'right',
                    html(row) {
                        return '<span style="color:#FF0000;">' + fomatFloat(row['pay_money']) + '</span>'
                    }
                },{
                    prop: 'note',
                    label: '备注',
                    minWidth:   '300px',
                    show_overflow_tooltip: true
                }
            ],
            fAllClient: [],
            collect_money: 0,
            pay_money: 0,
            entry_weight: 0,
            detry_weight: 0,
            select_id: []
        }
    },
    computed: {},
    methods: {
        get_entry_and_detry(param = {}) {
            this.loading = true
            this.cond.client_id = param.client_id ?? this.cond.client_id
            this.cond.product_name = param.product_name ?? this.cond.product_name
            this.cond.bill_name = param.bill_name ?? this.cond.bill_name
            this.cond.order_name = param.order_name ?? this.cond.order_name
            this.cond.type = param.type ?? this.cond.type
            this.cond.state = param.state ?? this.cond.state
            this.cond.date = param.date ?? this.cond.date
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            stock_entry_and_detry_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                        this.collect_money = fomatFloat(s.result.collect_money)
                        this.pay_money = fomatFloat(s.result.pay_money)
                        this.entry_weight = s.result.entry_weight
                        this.detry_weight = s.result.detry_weight
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_client() {
            client_list_request({})
                .then((s) => {
                    if (s.status === 0) {
                        this.fAllClient = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        output_entry_detry(param = {}) {
            const url = this.$store.state.baseURL 
            + 'stock/output_entry_detry'
            + '?client_id=' + param.client_id
            + '&product_name=' + param.product_name
            + '&bill_name=' + param.bill_name
            + '&order_name=' + param.order_name
            + '&type=' + param.type
            + '&state=' + param.state
            + '&date[]=' + param.date[0]
            + '&date[]=' + param.date[1]
            
            window.open(url, '_blank')
        }
    },
    filters: {
        diy_weight(v) {
            return thousandBitSeparator(fomatFloat(v)) + "KG"
        },
        diy_money(v) {
            return thousandBitSeparator(v) + "元"
        }
    },
    props: {},
    created() {
        this.get_entry_and_detry()
        this.get_all_client()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        EntryAndDetrySearchBarComponent,
        CommonTableComponent,
        CommonPageComponent
    },
    watch: {},
}
</script>

<style lang='less'>
.collect_moneyc {
    color: #00BB00;
}
.pay_moneyc {
    color: #FF0000;
}
.entry_weightc {
    color: #00BB00;
}
.detry_weightc {
    color: #FF0000;
}
</style>