import request from '../request'

//库存列表
export const stock_index_request = p => {
    return request({
        method:'GET',
        url:'stock/index',
        params: p
    })
}

//获取库存详情
export const stock_details_request = id => {
    return request({
        method:'GET',
        url:'stock/details',
        params: {
            id
        }
    })
}

//库存出库
export const stock_detry_request = data => {
    return request({
        method:'POST',
        url:'stock/detry',
        data
    })
}

//库存移库
export const stock_mvtry_request = data => {
    return request({
        method:'POST',
        url:'stock/mvtry',
        data
    })
}

//获取收发存记录
export const stock_entry_and_detry_request = p => {
    return request({
        method:'GET',
        url:'stock/entry_and_detry',
        params: p
    })
}

//导出收发存记录
export const output_entry_detry_request = p => {
    return request({
        method:'GET',
        url:'stock/output_entry_detry',
        params: p
    })
}

//库存统计
export const stock_count_request = p => {
    return request({
        method:'GET',
        url:'stock/count',
        params: p
    })
}

//批量放入出库单
export const stock_sel_orders_request = p => {
    return request({
        method:'GET',
        url:'stock/sel_orders',
        params: p
    })
}
//批量出库
export const stock_batch_detry_request = d => {
    return request({
        method:'POST',
        url:'stock/batch_detry',
        data: d
    })
}